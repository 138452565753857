<template>
  <div id="nav-bar">
    <div id="nav">
      <router-link to="/">Home</router-link> 
      <router-link to="/about">About</router-link> 
    </div>
    <div id="social-media-links">
      <a :href="linkedinUrl"> <div class="linkedinLogo logo"></div> </a>
      <a :href="githubUrl"> <div class="githubLogo logo"></div> </a>
    </div>
  </div>
  <router-view />
</template>

<script>
export default {
  data() {
    return {
      linkedinUrl: "https://www.linkedin.com/in/parteek-dheri",
      githubUrl: "https://github.com/dheri",
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav-bar {
  margin: 30px;
  display: flex;
  justify-content: space-around;
}

#nav-bar div {
  vertical-align: middle;
  display: inline-block;
}
/* 

#social-media-links {
  display: inline-block;
} 

#nav {
  display: inline-block;
}
*/
#nav a {
    width: 32px;
  height: 32px;
  margin-left: 1.5em;
  margin-right: 1.5em;
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.logo {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-left: 1.5em;
  width: 32;
  height: 32;
  padding: auto;
}

.linkedinLogo {
  background-color: #0a66c2;

  -webkit-mask: url(./assets/svgs/linkedin.svg) no-repeat center;
  mask: url(./assets/svgs/linkedin.svg) no-repeat center;
}

.githubLogo {
  background-color: #000000;
  -webkit-mask: url(./assets/svgs/github.svg) no-repeat center;
  mask: url(./assets/svgs/github.svg) no-repeat center;
}
</style>
